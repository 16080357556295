<template>
	<div>
		<div class="child-top">
			<div class="searchArea input-from">
				<!--                运动队设置-->
				<el-input class="input-with-select" clearable placeholder="请输入运动队名称" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="searchval" @keyup.enter.native="search">
				</el-input>
				<el-button class="searchBtn" @click="search" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<el-button @click="createde" class="new_btn" :disabled="$store.getters.permissionsStr('新增')" round>
					<span>新增</span>
				</el-button>
			</div>
		</div>
		<div style="background: #fff">
			<el-table :empty-text="tableData.length ? '' : '暂无数据'" :data="tableData" class="content-test-table" style="width: 100%" v-loading="tableLoading">
				<el-table-column show-overflow-tooltip type="selection" width="45"></el-table-column>
				<el-table-column label="序号" prop="order_no" show-overflow-tooltip type="index" width="80">
					<template slot-scope="scope">
						{{ (currentPage1 - 1) * 10 + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="编号" prop="code" show-overflow-tooltip width="120"></el-table-column>
				<el-table-column label="运动队名称" prop="name" show-overflow-tooltip></el-table-column>
				<el-table-column label="负责人" prop="charge_by" show-overflow-tooltip></el-table-column>
				<el-table-column label="备注" prop="demo" show-overflow-tooltip></el-table-column>
				<!-- <el-table-column label="状态" prop="status" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        <el-switch
                                :active-value="1"
                                :inactive-value="0"
                                :width="35"
                                @change="change(scope.row)"
                                :disabled="$store.getters.permissionsStr('启/停用')"
                                inactive-color="#ccc"
                                v-model="scope.row.status"
                        ></el-switch>
                    </template>
                </el-table-column> -->
				<el-table-column label="操作" show-overflow-tooltip width="200">
					<template slot-scope="scope">
						<el-button
							@click="Preview(scope.row)"
							class="right20"
							style="color: #0055e9"
							size="medium"
							type="text"
							v-show="scope.row.uuid"
							:disabled="$store.getters.permissionsStr('编辑')"
							>编辑
						</el-button>
						<el-button
							:class="scope.row.leaf_flag == 1 ? 'disabledColor' : ''"
							@click="addchild(scope.row)"
							class="right20 greenColor"
							size="medium"
							style="color: #1bbc9b"
							type="text"
							v-show="scope.row.uuid"
							:disabled="$store.getters.permissionsStr('新建子类') || scope.row.leaf_flag == 1"
							>新增子类
						</el-button>
						<el-button
							@click="Delete(scope.row)"
							class="redColor"
							size="medium"
							style="color: #df2722"
							type="text"
							v-show="scope.row.uuid"
							:disabled="$store.getters.permissionsStr('删除')"
							>删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				:current-page.sync="currentPage1"
				:page-size="10"
				:total="total"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>
		<!-- 新增编辑弹框 -->
		<div class="pop">
			<el-dialog
				:close-on-click-modal="false"
				:title="titleMap[dialogStatus]"
				:visible.sync="centerDialogVisible"
				@close="$refs['form'].resetFields()"
				center
				style="text-align: center"
				width="50%"
			>
				<div class="dialogBox" style="margin-bottom: 20px">
					<el-form :label-position="'left'" :model="form" :rules="rules" label-width="80px" ref="form">
						<div style="display: flex">
							<el-form-item label="隶属运动队" prop="uuid" v-if="centerDialogVisible" style="flex: 1">
								<el-cascader
									:disabled="dialogStatus === 'addchild' || dialogStatus === 'Preview'"
									:options="newTreeOption"
									:props="{ checkStrictly: true, value: 'uuid', label: 'name' }"
									@change="cascaderChange"
									style="width: 80%"
									v-model="form.uuid"
								></el-cascader>
							</el-form-item>
							<el-form-item label="编号" prop="code" style="flex: 1">
								<el-input auto-complete="off" disabled placeholder="请输入内容" style="width: 90%" v-model.number="form.code"></el-input>
							</el-form-item>
						</div>
						<div style="display: flex">
							<el-form-item label="名称" prop="name" style="flex: 1">
								<el-input auto-complete="off" placeholder="请输入内容" style="width: 80%" v-model="form.name"></el-input>
							</el-form-item>
							<el-form-item label="负责人" style="flex: 1">
								<el-input auto-complete="off" placeholder="请输入内容" style="width: 90%" v-model="form.charge_by"></el-input>
							</el-form-item>
						</div>
						<!-- <el-form-item label="上级领导">
                            <el-input auto-complete="off" placeholder="请输入内容" style="width:100%"
                                      v-model="form.leader"></el-input>
                        </el-form-item> -->
						<el-form-item label="备注" prop="demo">
							<el-input :rows="4" style="width: 96%" type="textarea" v-model="form.demo"></el-input>
						</el-form-item>
					</el-form>
				</div>

				<div class="form-bottom-button">
					<el-button @click="submitForm('form')" type="primary" v-no-more-click class="save_btn" round>确 定 </el-button>
					<el-button @click="centerDialogVisible = false" class="cancel_btn" round>取 消</el-button>
				</div>
			</el-dialog>
		</div>

		<!-- 穿梭框 -->
		<div class="newsub">
			<!--        插件穿梭框-->
			<el-dialog :title="title" :visible.sync="dialogFormVisible" :width="'30%'" center class="shuttleDialog">
				<div class="dialogBox" style="border: 1px solid #f6f6f6; border-radius: 4px">
					<el-tree
						:data="fromData"
						:props="{
							label: 'name',
							children: 'children',
						}"
						ref="logTree"
						show-checkbox
					></el-tree>
				</div>
				<div class="form-bottom-button">
					<el-button @click="suretransfer" type="primary">确 定</el-button>
					<el-button @click="dialogFormVisible = false">取 消</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
let form = {
	uuid: '',
	code: '',
	name: '',
	charge_by: '',
	leader: '',
	leaf_flag: '',
	order_no: '',
	status: '',
	department_path: '',
	demo: '',
}
// 输出结果
// const axios = require('axios');

// Make a request for a user with a given ID
import { setTimeout } from 'timers'

export default {
	data() {
		return {
			titleMap: {
				createde: '新增运动队',
				Preview: '编辑运动队',
				addchild: '新增子类',
			},
			//新增和编辑弹框标题
			dialogStatus: '',
			// dialogFormVisible  穿梭框的 会话弹窗
			getuuid: [], //点击当前表格UUid
			subUuid: [], //穿梭框选中uuid
			dialogFormVisible: false,
			transferrow: {},
			tableLoading: true, //loading
			data: [],
			total: 0,
			click_uuid: [],
			searchSelect: '', //顶部搜索框的下拉选择框
			value3: [],
			currentPage1: 1, //当前页数
			centerDialogVisible: false, //关闭弹框
			centerDialogVisibleEdit: false, //迁移弹框
			newCenterDialogVisible: false, //穿梭框
			searchval: '', //搜索框
			formLabelWidth: '60px', //新增，编辑输入框距离字的宽度
			form: {
				...form,
			},
			forms: {
				names: '',
			},
			tableData: [{ uuid: 0 }],
			options: [],
			type: '',
			// 新增、编辑菜单中表单的验证
			rules: {
				// code: {
				//     ...this.$rules.noEmpty,
				//     message: "请输入数字",
				//     type: "number"
				// },
				charge_by: { ...this.$rules.noEmpty, message: '负责人不能为空' },
				name: { ...this.$rules.noEmpty, message: '名称不能为空' },
				leader: { ...this.$rules.noEmpty, message: '上级领导不能为空' },
				uuid: { ...this.$rules.noEmpty, message: '上级运动队不能为空' },
				code: { ...this.$rules.noEmpty, message: '编号' },

				order_no: [
					{
						...this.$rules.noEmpty,
						message: '顺序不能为空',
						type: 'code',
					},
				],
				prefix: { ...this.$rules.noEmpty, message: '前缀字符不能为空' },
			},
			openNewData: {
				old: [],
				news: [],
			},

			editorData: null /*编辑时的行data*/,

			//    插件穿梭框数据
			title: '选择运动队',
			mode: 'transfer', // transfer addressList
			fromData: [],
			toData: [],
			newTreeOption: [],
			one: '',
		}
	},
	created() {
		this.getList({
			page: this.currentPage1,
		})
	},
	methods: {
		getDeparment() {
			this.$axios.post('/p/staff/tree').then(res => {
				if (res.data.code === 0) {
					this.$store.commit('setTreeMenu', res.data.data.department)
				}
			})
		},
		getCode(uuid) {
			this.$axios
				.post(
					'/p/department/code',
					this.$qs({
						uuid: uuid,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.form.code = res.data.data
					}
				})
		},
		//选中迁移事件
		clicktype(a) {
			this.click_uuid.push(a.uuid)
			if (this.click_uuid === []) {
				this.$message({
					message: response.data.message,
					type: 'success',
				})
			}
		},
		getList(query) {
			this.$axios
				.post('/p/department/lists', this.$qs(query))
				.then(response => {
					this.tableData = response.data.data.rows
					this.total = response.data.data.total
					this.tableLoading = false
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					// always executed
				})
		},

		cascaderChange(e) {
			if (this.dialogStatus === 'createde') {
				this.getCode(e[e.length - 1])
				this.department_uuid = e[e.length - 1]
			}
		},
		handleCurrentChange(val) {
			this.currentPage1 = val
			this.getList({ searchVal: this.searchval, page: this.currentPage1 })
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var realLength = 0
					var len = this.form.name.length
					var charCode = -1
					for (var i = 0; i < len; i++) {
						charCode = this.form.name.charCodeAt(i)
						if (charCode >= 0 && charCode <= 128) {
							realLength += 1
						} else {
							// 如果是中文则长度加2
							realLength += 2
						}
					}
					if (realLength > 50) {
						return this.$message({
							type: 'error',
							message: '运动队名称最大长度为50',
						})
					}

					if (!this.$sishiLength(this.form.charge_by)) {
						return this.$message({
							type: 'error',
							message: '负责人最大长度为40',
						})
					}

					if (!this.$bashiLength(this.form.demo)) {
						return this.$message({
							type: 'error',
							message: '备注最大长度为80',
						})
					}

					this.newjob()
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		search() {
			this.currentPage1 = 1
			//搜索内容
			this.getList({
				search_v: this.searchval,
				page: this.currentPage1,
				search_k: 'name',
			})
		},
		createde() {
			//新增
			// this.newTreeOption = JSON.parse(
			//     JSON.stringify(this.$store.state.treeMenu)
			// );
			// this.newTreeOption = this.newTreeOption ? this.newTreeOption : []
			// console.log(this.newTreeOption)
			// this.newTreeOption.unshift({
			//     name: "一级运动队",
			//     uuid: "classA"
			// });
			this.$axios
				.post('/p/staff/tree', this.$qs({}))
				.then(response => {
					// this.getDeparment();
					this.newTreeOption = response.data.data.department
					this.newTreeOption.unshift({
						name: '一级运动队',
						uuid: 'classA',
					})
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					this.type = 'create'
					this.centerDialogVisible = true
					this.getList()
					this.$nextTick(() => {
						this.getCode('classA')
					})
					//新增弹框标题
					this.dialogStatus = 'createde'
					this.form = {
						...form,
						uuid: 'classA',
					}
				})
		},
		Preview(row) {
			//编辑
			this.$axios
				.post('/p/staff/tree', this.$qs({}))
				.then(response => {
					// this.getDeparment();
					this.newTreeOption = response.data.data.department
					this.newTreeOption.unshift({
						name: '一级运动队',
						uuid: 'classA',
					})
				})
				.catch(error => {
					console.log(error)
				})
			this.editorData = row
			this.type = 'preview' //区别编辑或者新增
			this.$axios
				.post('/p/department/info', this.$qs({ uuid: row.uuid }))
				.then(response => {
					this.form = response.data.data //接口返回需要编辑的数据
					this.form.uuid = response.data.data.puuid === '0' ? 'classA' : row.department_all_path.split('/')
					// this.getList({page: this.currentPage1});
					// console.log(this.form)
					// this.getDeparment();
				})
				.catch(error => {
					console.log(error)
					this.$message({ message: error.data.message })
				})
			//编辑弹框标题
			this.dialogStatus = 'Preview'
			this.centerDialogVisible = true
		},
		/*
            新增子类
        */
		addchild(row) {
			this.type = 'addchild' //区别编辑或者新增

			this.newTreeOption = JSON.parse(JSON.stringify(this.$store.state.treeMenu))
			this.getCode(row.uuid)
			this.centerDialogVisible = true
			//新增弹框标题
			this.dialogStatus = 'addchild'
			this.form.code = ''
			this.form.name = ''
			this.form.charge_by = ''
			this.form.leader = ''
			this.form.demo = ''
			this.form.uuid = ''
			setTimeout(() => {
				this.form.uuid = row.uuid
			}, 0)
		},
		newjob() {
			//保存弹框
			if (this.type === 'create') {
				//新增弹框的保存

				this.$axios
					.post(
						'/p/department/add',
						this.$qs({
							puuid: this.form.uuid === 'classA' ? 'classA' : typeof this.form.uuid == 'string' ? this.form.uuid : this.form.uuid[this.form.uuid.length - 1],
							code: this.form.code,
							name: this.form.name,
							charge_by: this.form.charge_by,
							leader: this.form.leader,
							demo: this.form.demo,
						})
					)
					.then(response => {
						if (response.data.code === 0) {
							this.$message({
								message: response.data.message,
								type: 'success',
							})
							this.getList({ page: this.currentPage1 })
							this.centerDialogVisible = false
							// this.$axios.post("/p/staff/tree").then((res) => {
							//     if (res.data.code == 0) {
							//         this.$store.commit('setTreeMenu', res.data.data.department);
							//     }
							// })
							// this.getDeparment();
						} else {
							this.$message({
								message: response.data.message,
								type: 'error',
							})
						}
					})
					.catch(error => {
						this.$message({
							message: error.data.message,
						})
					})
			} else if (this.type === 'addchild') {
				//新增子类的保存
				this.form.puuid = this.form.uuid
				this.form.uuid = this.form.uuid
				// console.log(this.form.uuid)

				this.$axios
					.post(
						'/p/department/add',
						this.$qs({
							puuid: typeof this.form.puuid == 'string' ? this.form.puuid : this.form.puuid[this.form.puuid.length - 1],
							code: this.form.code,
							name: this.form.name,
							charge_by: this.form.charge_by,
							leader: this.form.leader,
							demo: this.form.demo,
						})
					)
					.then(response => {
						if (response.data.code === 0) {
							this.getList({ page: this.currentPage1 })
							this.centerDialogVisible = false
							this.$message({
								message: response.data.message,
								type: 'success',
							})
							// this.getDeparment();
						} else {
							this.$message({
								message: response.data.message,
								type: 'error',
							})
						}
					})
					.catch(error => {
						this.$message({ message: error.data.message })
					})
			} else {
				//编辑弹框的保存
				// let department = JSON.parse(JSON.stringify(this.form.puuid));
				this.form.uuid = this.editorData.uuid
				// this.form.puuid = typeof department == 'string' ? department : department[department.length - 1];
				this.$axios
					.post(
						'/p/department/update',
						this.$qs({
							uuid: this.form.uuid,
							puuid: this.form.puuid,
							code: this.form.code,
							name: this.form.name,
							charge_by: this.form.charge_by,
							leader: this.form.leader,
							demo: this.form.demo,
						})
					)
					.then(response => {
						if (response.data.code === 0) {
							this.$message({
								message: response.data.message,
								type: 'success',
							})
							// this.getDeparment();
						} else {
							this.$message({
								message: response.data.message,
								type: 'error',
							})
						}
						this.getList({ page: this.currentPage1 })
						this.centerDialogVisible = false
					})
					.catch(error => {
						this.$message({
							message: error.data.message,
						})
					})
			}
		},
		Delete(row) {
			//删除数据
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				this.$axios
					.post('/p/department/delete', this.$qs({ uuid: row.uuid }))
					.then(response => {
						this.operatinguuid = []
						if (response.data.code === 0) {
							this.$message({
								message: '删除成功',
								type: 'success',
							})
							// 解决跳页后删除本页最后一条数据查询不跳转问题
							if (this.currentPage * 10 - 9 === this.total) {
								this.currentPage -= 1
							}
							if (this.currentPage === 0) {
								this.currentPage = 1
							}
							// this.getDeparment();
						} else {
							this.$message({
								message: response.data.message,
								type: 'error',
							})
						}
					})
					.catch(error => {
						this.operatinguuid = []
					})
					.finally(() => {
						this.getList({
							page: this.currentPage1,
							name: this.searchval,
						})
					})
			})
		},
		//修改状态
		change(row) {
			this.$axios
				.post('/p/department/able', this.$qs({ uuid: row.uuid }))
				.then(response => {
					if (response.data.code == 0) {
						this.$message({
							message: response.data.message,
							type: 'success',
						})
						// this.getDeparment();
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})

				.finally(() => {
					// always executed
					this.getList({ page: this.currentPage1 })
				})
		},
		suretransfer() {
			//穿梭框确定

			this.dialogFormVisible = false
			let arr = []
			this.$refs.logTree.getCheckedNodes().forEach((i, k) => {
				if (i.children == undefined) {
					arr.push(i.uuid)
				}
			})
			if (arr.length == 0) {
			} else {
				this.$axios
					.post(
						'/p/department/transfer',
						this.$qs({
							uuid: this.getuuid,
							department_uuid: arr.join(','),
						})
					)
					.then(response => {
						if (response.data.code == 0) {
							this.$message({
								// message:response.data.message,
								message: '迁移成功',
								type: 'success',
							})
						} else {
							this.$message({
								message: response.data.message,
								type: 'error',
							})
						}

						this.newSub()
					})
					.catch(error => {
						this.$message({ message: error.data.message })
					})
					.finally(() => {
						// always executed
						this.getuuid = ''
						this.subUuid = ''
						this.getList({ page: this.currentPage1 })
					})
			}
		},
		// newSub 迁移会话框
		newSub(row) {
			this.getuuid = row.uuid
			this.$axios
				.post('/p/staff/tree', this.$qs({}))
				.then(response => {
					this.fromData = response.data.data.department
				})
				.catch(error => {
					// handle error
					console.log(error)
				})
				.finally(() => {
					this.type = 'create'
					this.dialogFormVisible = true
				})
		},
		checked(nodeObj) {
			//点击穿梭框里的多选框
			this.subUuid = nodeObj.uuid
		},
	},
	//    插件穿梭框的方法
	// 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
	changeMode() {
		if (this.mode == 'transfer') {
			this.mode = 'addressList'
		} else {
			this.mode = 'transfer'
		}
	},
	// components: {treeTransfer}, // 注册
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../assets/cssFunction.scss';
@import '../../assets/private_yuan_public.scss';

.child-top {
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}

.aboveTableArea {
	box-sizing: border-box;

	.buttonArea {
		padding-right: 5px;
		@include buttonAreaLength(1);
	}

	// border: 1px solid rgba(227, 227, 227, 1) !important;
}
.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;

	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}

	.el-form {
		padding-right: 30px;
	}
}

// .el-cascader {
//     width: 100%;
// }
.el-tree /deep/ .el-tree-node__content {
	padding-top: 10px;
}

.el-tree-node__content /deep/ .el-tree-node__label {
	padding-left: 10px;
}

.dialogFormChecked {
	width: 50%;
	float: left;
	margin-right: 0;
	padding-left: 14px;
	box-sizing: border-box;

	span {
		margin-left: 4px;
	}
}

.el-pagination {
	padding-bottom: 10px;
}
</style>
<style>
.el-tree-node__label {
	padding-left: 10px;
}
</style>
